import React from 'react'

class DocumentiUtili extends React.Component {

  render() { 
    const docs = this.props.data;
    const section = this.props.section;
    
    return (
      <div className="webcontent">
         {!!section &&
          <h2 className="nav-menu-portlet__title">{section}</h2>
        }
      
        <ul className="webcontent__list">
          {docs.map((item, index) => {
          return <li className="webcontent__item">
            <a key={index} href={item.file.url} title={item.title} className="webcontent__link">{item.title}</a>
          </li>
          })}
        </ul>
      </div>
    )
  }

}

export default DocumentiUtili;